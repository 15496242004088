import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom'
import Web3 from "web3";
import Web3Modal from "web3modal";

//Import
import { ThemeContext } from "../../../context/ThemeContext";
import PageHead from '../Aera/PageHead';
import { providerOptions } from "../../../config";

const Presale = () => {

    const [userAddress, setUserAddress] = useState();

    const [referralLink, setReferralLink] = useState()

    const [referralCount, setReferralCount] = useState(0)

    const [buttonText, setButtonText] = useState("Join Whitelist")

    const [buttonDisabled, setButtonDisabled] = useState(false)

    const queryString = window.location.search;

    const urlParams = new URLSearchParams(queryString);

    const referrer = urlParams.get('ref')

    console.log(referrer)

    let provider;

    const web3Modal = new Web3Modal({
        cacheProvider: true, // optional
        providerOptions, // required
        disableInjectedProvider: false, // optional. For MetaMask / Brave / Opera.
    });

    const copyLink = () => {

        navigator.clipboard.writeText(referralLink);

        // Alert the copied text
        alert("Referral link copied");
    }

    const joinWhitelist = async () => {

        if (userAddress == "" || userAddress.length < 35) {

            alert("Invalid address");

            return false;

        }

        setButtonText("Requesting....");

        setButtonDisabled(true);

        let request = await fetch("https://api.cryvto.com/airdrop.php?walletAddress=" + userAddress + "&referrer=" + referrer);

        let response = await request.json();

        console.log(response)

        if (response.data == "added") {

            alert("Your request has been processed, click ok or close and share the loaded tweet to complete whitelist registration!")

            window.location.replace("https://twitter.com/intent/tweet?text=I%20just%20applied%20to%20join%20@mypaperhands%20whitelist.%20Retweet%20their%20pinned%20tweet%2C%20join%20their%20telegram%20%3D%3E%20https%3A//t.me/paperhandscoin%20and%20visit%20the%20link%20to%20join%20%3D%3E%20https%3A//app.paperhands.site/whitelist");

        } else {

            alert("Your request has been processed, click ok or close and share the loaded tweet to complete whitelist registration!");

            window.location.replace("https://twitter.com/intent/tweet?text=I%20just%20applied%20to%20join%20@mypaperhands%20whitelist.%20Retweet%20their%20pinned%20tweet%2C%20join%20their%20telegram%20%3D%3E%20https%3A//t.me/paperhandscoin%20and%20visit%20the%20link%20to%20join%20%3D%3E%20https%3A//app.paperhands.site/whitelist");

        }
    }

    const getReferralCount = async (userAddress) =>{

        let request = await fetch("https://api.cryvto.com/referralCount.php?walletAddress=" + userAddress);

        let response = await request.text();

        console.log(request)

        setReferralCount(response);


        
    }

    useEffect(async () => {

        if (web3Modal) {
            let dollarUSLocale = Intl.NumberFormat('en-US');

            if (web3Modal.cachedProvider == "injected" || web3Modal.cachedProvider == "walletconnect") {

                provider = await web3Modal.connect();

                const web3 = new Web3(provider);
                const chainID = await web3.eth.getChainId();
                if (chainID != 56) {
                    return false;
                }

                let accounts = await web3.eth.getAccounts();
                let selectedAccount = accounts[0];
                let account = selectedAccount;
                const walletAddress = account;
                setUserAddress(walletAddress)
                setReferralLink(`https://app.paperhands.site/whitelist?ref=${walletAddress}`);
                getReferralCount(walletAddress);
                console.log(walletAddress)
                const walletAddress1 = walletAddress.substring(0, 4);
                const walletAddress2 = walletAddress.substring(38);
                const balance = await web3.eth.getBalance(account);

            }

        }

    }, [])


    const {
        changeBackground, changeNavigationHader,
        changePrimaryColor, chnageSidebarColor
    }
        = useContext(ThemeContext);


    return (
        <>
            <PageHead activePage="Whitelist" pageName="Whitelist" />

            <div className="row">
                <div className="col-xl-12 col-xxl-12">
                    <h1>Join Presale Whitelist</h1>
                    <p>Click on Join Whitelist button below to whitelist your address for the presale</p>
                </div>
            </div>

            <div className="row">

                <div className="col-xl-6 col-sm-12">

                    <div className="basic-form ">


                        <div className="input-group mb-3 border-0" style={{
                            webkitBoxShadow: "0px 0px 10px 0px rgba(186,186,186,1)",
                            mozBoxShadow: "0px 0px 10px 0px rgba(186,186,186,1)", boxShadow: "0px 0px 10px 0px rgba(186,186,186,1)", borderRadius: "15px"
                        }}>

                            {!buttonDisabled &&
                                <a className="btn btn-success" type="button" style={{ width: "100%" }} onClick={() => joinWhitelist()}>
                                    {buttonText}
                                </a>
                            }

                            {buttonDisabled &&
                                <a disabled="disabled" className="btn btn-success" type="button" style={{ width: "100%" }} onClick={() => joinWhitelist()}>
                                    {buttonText}
                                </a>
                            }


                        </div>


                    </div>
                </div>

            </div>
            <hr />
            <div className="row">
                <div className="col-xl-12 col-xxl-12">
                    <h1>Referral Link</h1>
                    <p>Use your referral link to invite others to join the presale whitelist and stand a chance of winning $1,000 </p>
                </div>
            </div>

            <div className="row">

                <div className="col-xl-6 col-sm-12">

                    <div className="basic-form ">


                        <div className="input-group mb-3 border-0" style={{
                            webkitBoxShadow: "0px 0px 10px 0px rgba(186,186,186,1)",
                            mozBoxShadow: "0px 0px 10px 0px rgba(186,186,186,1)", boxShadow: "0px 0px 10px 0px rgba(186,186,186,1)", borderRadius: "15px"
                        }}>

                            <input value={referralLink} style={{ color: "#000", border: 0 }} type="text" className="form-control bg-white" />

                            <button className="btn btn-success" type="button" onClick={() => { copyLink() }}>
                                Copy Link
                            </button>


                        </div>


                    </div>
                </div>

            </div>

            <div className="row">
                <div className="col-xl-12 col-xxl-12">
                    <h1>Referral Count: {referralCount}</h1>
                </div>
            </div>

        </>
    )

}
export default Presale;